import bg from "./bg/index";
import en from "./en/index";
import fr from "./fr/index";
import ro from "./ro/index";

export default {
    bg,
    en,
    fr,
    ro,
};
